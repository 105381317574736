import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setOrderedPages } from 'slices/editorSlice';
import PreviewPage from '../PreviewPage';
import { StyledRow, StyledCol } from './styles';

const PreviewContent = () => {
  const dispatch = useDispatch();
  const pages = useSelector((state) => state.editor.linesheetData.pages);
  const orientation = useSelector((state) => state.editor.linesheetData.settings.orientation);

  const [currentPos, setCurrentPos] = useState(null);
  const [newPos, setNewPos] = useState(null);

  let colStyles = {
    maxWidth: '300px',
    maxHeight: '400px',
  };
  if (orientation === 'landscape') {
    colStyles = {
      maxHeight: '300px',
      maxWidth: '400px',
    };
  }

  const handleDragStart = (index) => {
    setCurrentPos(index);
  };

  const handleDragEnter = (index) => {
    if (index !== currentPos) {
      setNewPos(index);
    }
  };

  const handleDragEnd = () => {
    if (currentPos !== null && newPos !== null) {
      const reorderedPages = [...pages];
      const [movedPage] = reorderedPages.splice(currentPos, 1);
      reorderedPages.splice(newPos, 0, movedPage);

      // Update page_number based on the new order
      const updatedPages = reorderedPages.map((page, idx) => ({
        ...page,
        page_number: idx,
      }));
      dispatch(setOrderedPages(updatedPages));
      setCurrentPos(null);
      setNewPos(null);
    }
  };

  return (
    <StyledRow>
      {pages.map((page, idx) => (
        <StyledCol
          key={idx}
          style={colStyles}
          draggable
          onDragStart={() => handleDragStart(idx)}
          onDragEnter={() => handleDragEnter(idx)}
          onDragEnd={handleDragEnd}
        >
          <PreviewPage pageData={page} pageIdx={page.page_number} height={colStyles.maxHeight} />
        </StyledCol>
      ))}
    </StyledRow>
  );
};

export default PreviewContent;
