import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertContainer, StyledAlert } from './styles';
import { clearAlert } from 'slices/alertMessageSlice';

const AlertMessages = () => {
  const dispatch = useDispatch();
  const { message, type } = useSelector((state) => state.alertMessage);

  const handleAlertClose = (e) => {
    dispatch(clearAlert());
  };

  return (
    message && (
      <AlertContainer>
        <StyledAlert message={message} type={type} showIcon closable onClose={handleAlertClose} />
      </AlertContainer>
    )
  );
};

export default AlertMessages;
