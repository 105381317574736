import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'Muli', Helvetica, Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    margin: 0;
    padding: 0;
    background: #fafafa;
  }
  
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  a {
    color: #ccc;
    &:hover {
      color: #ccc;
    }
  }

  h3 {
    font-family: 'Muli', Helvetica, Arial, sans-serif;
    font-weight: 600;
    line-height: 1.5;
    color: #212529;
    margin: 0;
    padding: 0;

  }

  .custom-country-dropdown:focus,
  .custom-region-dropdown:focus {
    /* Remove focus styles */
    outline: none;
    box-shadow: none;
  }

  .ant-dropdown {
    z-index: 9999 !important;
    .ant-dropdown-menu{
      padding: 0;
      .ant-dropdown-menu-item{
        padding-top: 8px;
        padding-bottom: 8px;
        border-radius: 0;
        font-size: 12px;

        &:hover {
          background-color: #02b183;
          color: #fff;
        }

        &.wrapper-header-user-menu-dropdown-top {
          background: #000;
          color: #fff;
          font-size: 12px;
          font-weight: 600;
          pointer-events: none;
        }
      }
      .ant-dropdown-menu-item-active{
        background-color: #02b183;
        color: #fff;
      }
    }
  }

  .ant-message, .categories-dropdown{
    z-index: 9999;
  }

  .table-image-wrapper {
      width: 50px;
      height: 50px;
      display: flex;
    justify-content: center;
    align-items: center;
    img{
      max-width: 100%;
      max-height: 100%;
      border: 1px solid #d0d5d9;
      display: flex;
      align-items: center;
    }
  }

  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width: 576px) {
    .container {
      max-width: 540px;
    }
  }

  @media (min-width: 768px) {
    .container {
      max-width: 720px;
    }
  }

  @media (min-width: 992px) {
    .container {
      max-width: 960px;
    }
  }

  @media (min-width: 1400px) {
    .container {
      max-width: 1340px;
    }
  }

  .loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000000;
    backdrop-filter: blur(2px);
    pointer-events: none;
    transition: opacity 150ms ease-in-out;
    opacity: 0;

    .loading-inner {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      > i {
        position: absolute;
        margin-left: -24px;
        margin-top: -32px;
        color: $primary;
        font-size: 48px;
        pointer-events: none;
      }

      > p {
        margin-top: 24px;
        font-size: 18px;
        font-weight: 600;
      }
    }

    &.showing {
      opacity: 1;
      pointer-events: all;
    }
  }

  .error{
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: #dc3545;
  }

  @media all and (max-width: 1400px) {
    .ant-table {
      width: 100% !important;
    }
  
    .ant-spin-container {
      overflow-x: auto;
    }
  }
  
  @media all and (max-width: 1100px) {
    .ant-table-wrapper.my-table {
      min-width: 850px !important;
    }
  }

  .ant-table-thead > tr > th {
    padding: 16px 5px;
  }

  .ant-table-thead th.ant-table-column-has-sorters {
    font-family: Muli;
    font-size: 12px;
    font-weight: 900;
    color: #b8becd;
    vertical-align: top !important;
  }
  
  .ant-table-column-sorters {
    font-family: Muli;
    font-size: 12px;
    font-weight: 900;
    color: #b8becd;
  }
  
  .ant-table-column-sorter-inner {
    display: flex !important;
  }
  .ant-table-fixed-header .ant-table-scroll .ant-table-header {
    overflow: hidden; /* instead of overflow:scroll */
  }

  .ant-tooltip{
    font-family: Muli !important;
    font-size: 12px !important;
    max-width: 23% !important;
  }

  .editor-font-select{
    border-radius: 3px;
    border: 2px solid #3c4145;
    background-color: #242424;
    .ant-select-item{
      color: #fff !important;
      &:hover {
        background-color: #4a4a4a;
      }
    }
    .ant-select-item-option-selected{
      background-color: #4a4a4a !important;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #02b183 !important;
  border-color: #02b183 !important;
  &:hover {
    border-color: #02b183 !important;
  }
  &:focus {
    border-color: #02b183 !important;
  }
}

.ant-checkbox-checked:after {
  border-color: #02b183 !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #02b183 !important;
  border-color: #02b183 !important;
}

.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #02b183 !important;
}
.ant-modal-wrap{
  overflow: unset !important;
}

.ant-color-picker-inner{
  .ant-color-picker-alpha-input{
    display: none;
  }
}
`;

export default GlobalStyles;
