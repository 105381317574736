import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useUpdateLinesheetMutation } from 'api/linesheetApi';
import LinesheetEditorHeader from './LinesheetEditorHeader';
import LeftPanel from './LeftPanel';
import EditorPanel from './EditorPanel';
import RightPanel from './RightPanel';
import Loader from 'components/Common/Loader';
import {
  resetEditorStates,
  setShowDownloadConfirmationModal,
  setProductDescriptionModalVisibility,
  updateProductDescription,
} from 'slices/editorSlice';
import { inActiveSubscription } from 'slices/authSlice';
import LinesheetPreviewPage from './LinesheetPreviewPage';
import AlertMessages from '../Common/AlertMessages';
import ConfirmTemplateModal from './ConfirmTemplateModal';
import DownloadConfirmationModal from './DownloadConfirmationModal';
import { message } from 'antd';
import BackModal from './BackModal';
import NewNameModal from './NewNameModal';
import SubscriptionModal from 'components/MyProfile/SubscriptionPlanModal';
import { setAlert } from 'slices/alertMessageSlice';
import SetDescriptionModal from './SetDescriptionModal';
import {
  MainContainer,
  LinesheetEditorBody,
  StyledLeftPanel,
  StyledEditorPanel,
  StyledRightPanel,
} from './styles';
const LinesheetEditor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isBack, setIsBack] = useState(false);
  const [showNewNameModal, setShowNewNameModal] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  const { id, name, settings, pages, pdf_url } = useSelector(
    (state) => state.editor.linesheetData,
    shallowEqual,
  );
  const inPreview = useSelector((state) => state.editor.inPreview, shallowEqual);
  const isEditorLoading = useSelector((state) => state.editor.isEditorLoading, shallowEqual);
  const showSetProductDescriptionModal = useSelector(
    (state) => state.editor.showSetProductDescriptionModal,
  );
  const { status, planType } = useSelector((state) => state.auth.user.subscription);

  const [updateLinesheet, { isLoading: updateLinesheetLoading }] = useUpdateLinesheetMutation();

  const handleDntSave = () => {
    dispatch(resetEditorStates());
    navigate('/linesheets');
  };

  const saveAndDownloadApi = async (exportPDF, saveFromBackModal = false) => {
    const payload = { settings, pages, exportPDF };

    try {
      const result = await updateLinesheet({ linesheetId: id, payload }).unwrap();
      message.success(result?.message);
      if (planType === 'oneTime') dispatch(inActiveSubscription());
      if (saveFromBackModal) navigate('/linesheets');
    } catch (error) {
      message.error('Failed to save linesheet');
    }
  };

  const handleSave = (exportPDF, saveFromBackModal = false) => {
    setIsBack(false);
    if (pages.length <= 0) return message.error('Please add some pages');

    const notFormattedPageIndexes = pages
      .map((page, index) => (page.settings.isNotFormatted ? index + 1 : -1))
      .filter((index) => index !== -1);
    if (notFormattedPageIndexes.length > 0) {
      const indexesString = notFormattedPageIndexes.join(', ');
      const errorMsg = `Please first update the contents on these pages: ${indexesString}`;
      dispatch(setAlert({ message: errorMsg, type: 'error' }));
      return;
    }

    if ((status === 'inactive' || planType === null) && exportPDF) {
      if (pdf_url !== null) {
        dispatch(setShowDownloadConfirmationModal());
      } else {
        setShowSubscriptionModal(true);
      }
      return;
    }

    saveAndDownloadApi(exportPDF, saveFromBackModal);
  };

  const handleModalClose = () => {
    dispatch(setProductDescriptionModalVisibility());
  };

  const handleDescriptionSave = (editableContent) => {
    dispatch(updateProductDescription({ value: editableContent }));
  };

  return (
    <MainContainer>
      <LinesheetEditorHeader
        linesheetName={name}
        setIsBack={setIsBack}
        handleSave={() => handleSave(false)}
        handleSaveAsNew={() => setShowNewNameModal(true)}
        handleGeneratePDF={() => handleSave(true)}
        isBack={isBack}
        inPreview={inPreview}
      />
      {inPreview ? (
        <LinesheetPreviewPage />
      ) : (
        <LinesheetEditorBody>
          <StyledLeftPanel>
            <LeftPanel />
          </StyledLeftPanel>
          <StyledEditorPanel>
            <EditorPanel />
          </StyledEditorPanel>
          <StyledRightPanel>
            <RightPanel />
          </StyledRightPanel>
        </LinesheetEditorBody>
      )}
      <BackModal
        isBack={isBack}
        setIsBack={setIsBack}
        handleDntSave={handleDntSave}
        handleSave={() => handleSave(false, true)}
      />
      <NewNameModal showNewNameModal={showNewNameModal} setShowNewNameModal={setShowNewNameModal} />
      <AlertMessages />
      <ConfirmTemplateModal />
      <DownloadConfirmationModal
        setShowSubscriptionModal={setShowSubscriptionModal}
        saveAndDownloadApi={() => saveAndDownloadApi(true)}
      />
      <SubscriptionModal
        isOpen={showSubscriptionModal}
        setShowSubscriptionModal={setShowSubscriptionModal}
      />
      <SetDescriptionModal
        open={showSetProductDescriptionModal}
        html={''}
        handleModalClose={handleModalClose}
        handleSave={handleDescriptionSave}
      />
      <Loader isLoading={updateLinesheetLoading || isEditorLoading} />
    </MainContainer>
  );
};

export default React.memo(LinesheetEditor);
