import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import globals from 'websiteGlobals';
import { DownOutlined } from '@ant-design/icons';
import {
  updateFontSettings,
  updateLinesheetFontSettings,
  updateGlobalProductFontSettings,
} from 'slices/editorSlice';
import EditorTextDecorationToolsComponent from '../EditorTextDecorationTools';
import EditorTextDirectionToolsComponent from '../EditorTextDirectionTools';
import {
  StyledFontAwesomeIcon,
  StyledRow,
  StyledCol,
  StyledSelect,
  StyledOption,
  StyledColorPicker,
  StyledLabel,
  StyledTooltip,
} from './styles';

const FontSettings = ({ isGlobal, fontSettings, isProductGlobal = false, type = '' }) => {
  const [open, setOpen] = useState(false);
  const fonts = globals.constants.fonts;
  const fontSizes = globals.constants.fontSizes;
  const dispatch = useDispatch();
  const TextDecorationTools = EditorTextDecorationToolsComponent();
  const TextAlignmentTools = EditorTextDirectionToolsComponent();

  const handleChange = (key, value) => {
    const payload = { key, value, field: type };
    const action = isGlobal
      ? updateLinesheetFontSettings
      : isProductGlobal
      ? updateGlobalProductFontSettings
      : updateFontSettings;
    dispatch(action(payload));
  };

  const handleFontChange = (value) => handleChange('font', value);
  const handleFontSizeChange = (value) => handleChange('size', value);
  const handleColorChange = (value) => handleChange('color', `#${value.toHex()}`);

  return (
    <>
      <StyledRow>
        <StyledSelect
          onChange={handleFontChange}
          defaultValue={'Font'}
          value={fontSettings.font}
          popupClassName="editor-font-select"
        >
          {fonts.map((font) => (
            <StyledOption key={font.key} value={font.name} style={{ fontFamily: font.name }}>
              {font.name}
            </StyledOption>
          ))}
        </StyledSelect>
      </StyledRow>
      <StyledRow>
        <StyledSelect
          onChange={handleFontSizeChange}
          defaultValue={'Font Size'}
          value={fontSettings.size}
          popupClassName="editor-font-select"
        >
          {fontSizes.map((size) => (
            <StyledOption key={size} value={size}>
              {size}
            </StyledOption>
          ))}
        </StyledSelect>
      </StyledRow>
      <StyledRow justify="space-between">
        <StyledCol xs={24} xl={14}>
          <StyledLabel textalign={'left'}>Text Color</StyledLabel>
        </StyledCol>
        <StyledCol xs={24} xl={8}>
          <StyledColorPicker
            onChange={handleColorChange}
            defaultValue="#000"
            value={fontSettings.color}
            open={open}
            onOpenChange={setOpen}
            showText={() => (
              <DownOutlined
                rotate={open ? 180 : 0}
                style={{
                  color: '#000',
                  filter: 'invert(1)',
                }}
              />
            )}
          />
        </StyledCol>
      </StyledRow>
      <StyledRow justify="space-between" gap={10}>
        <StyledCol sm={24} xl={10}>
          <StyledRow nomargin={'true'} textalign={'center'}>
            {TextDecorationTools.map((tool, index) => (
              <StyledCol span={8} key={index}>
                <StyledTooltip title={tool.tooltip} placement="left">
                  <StyledFontAwesomeIcon
                    icon={tool.icon}
                    onClick={() => tool.action(tool.name, isGlobal, isProductGlobal, type)}
                  />
                </StyledTooltip>
              </StyledCol>
            ))}
          </StyledRow>
        </StyledCol>
        <StyledCol sm={24} xl={10}>
          <StyledRow nomargin={'true'} textalign={'center'}>
            {TextAlignmentTools.map((tool, index) => (
              <StyledCol span={8} key={index}>
                <StyledTooltip title={tool.tooltip} placement="left">
                  <StyledFontAwesomeIcon
                    icon={tool.icon}
                    onClick={() => tool.action(tool.name, isGlobal, isProductGlobal, type)}
                  />
                </StyledTooltip>
              </StyledCol>
            ))}
          </StyledRow>
        </StyledCol>
      </StyledRow>
    </>
  );
};

export default FontSettings;
