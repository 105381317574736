import styled from 'styled-components';

export const StyledRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: calc(100vh - 92px);
  overflow-y: scroll;
`;

export const StyledCol = styled.div``;