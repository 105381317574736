export const undoRedoMiddleware = (store) => (next) => (action) => {
  // List of actions that should not trigger the saveState action
  const notAllowedActions = [
    'linesheet/saveState',
    'linesheet/resetEditorStates',
    'linesheet/setLinesheet',
    'linesheet/setLinesheetProducts',
    'linesheet/setActiveAction',
    'linesheet/setContentIsOverflow',
    'linesheet/setInPreview',
    'linesheet/setSelectedPage',
    'linesheet/setSelectedContentId',
    'linesheet/addTemplateId',
    'linesheet/setTemplateModalVisibility',
    'linesheet/setShowConfirmTemplateModal',
    'linesheet/setShowDownloadConfirmationModal',
    'linesheet/setIsEditing',
    'linesheet/setSelectedProductIds',
    'linesheet/undo',
    'linesheet/redo',
    'linesheet/setIsEditorLoading',
    'linesheet/unsetSelection',
    'linesheet/changeProductCategoryVisibility',
  ];

  // Check if the action belongs to the editorSlice and is not in the notAllowedActions list
  if (action.type.startsWith('linesheet/') && !notAllowedActions.includes(action.type)) {
    const { previousLinesheetData, linesheetData } = store.getState().editor; // Accessing the past states and current state

    const { settings, pages } = linesheetData;
    // Create a new past array by adding the current state to the past states
    const newPreviousLinesheetData = [
      ...previousLinesheetData,
      JSON.parse(JSON.stringify({ settings, pages })),
    ];

    // If there are more than 10 past states, remove the oldest one
    if (newPreviousLinesheetData.length > 10) {
      newPreviousLinesheetData.shift();
    }

    // Dispatch the action to save the trimmed past states array
    store.dispatch({
      type: 'linesheet/saveState',
      payload: newPreviousLinesheetData,
    });
  }

  // Proceed with the action
  return next(action);
};
