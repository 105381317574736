import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { rotatePage, undo, redo } from 'slices/editorSlice';
import { faRotateLeft, faRotateRight, faRotate } from '@fortawesome/free-solid-svg-icons';
import PreviewContent from './PreviewContent';
import RightClickMenu from '../Playground/RightClickMenu';
import PreviewMenuOptions from './previewMenuOptions';
import {
  StyledPreviewContainter,
  StyledHeader,
  StyledTooltip,
  StyledButton,
  StyledFontAwesomeIcon,
} from './styles';

const LinesheetPreviewPage = () => {
  const dispatch = useDispatch();
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [menuVisible, setMenuVisible] = useState(false);
  const previewMenuOptions = PreviewMenuOptions();

  const handleUndo = () => {
    dispatch(undo());
  };
  const handleRedo = () => {
    dispatch(redo());
  };
  const handleRotate = () => {
    dispatch(rotatePage());
  };

  const bgClickHandler = (e) => {
    e.preventDefault();
    const { clientX, clientY } = e;
    if (!e.target.closest('.linesheet-editor-playground-page')) {
      const isBottomHalf = clientY > window.innerHeight / 2;
      const top = isBottomHalf ? clientY : clientY;
      const transform = isBottomHalf ? 'translateY(-100%)' : 'translateY(0)';
      setMenuPosition({ y: top, x: clientX, transform });
      setMenuVisible(true);
    }
  };

  return (
    <StyledPreviewContainter onContextMenu={bgClickHandler} onClick={() => setMenuVisible(false)}>
      <StyledHeader>
        <StyledTooltip title={'Undo'} placement="bottom">
          <StyledButton onClick={() => handleUndo()}>
            <StyledFontAwesomeIcon icon={faRotateLeft} />
          </StyledButton>
        </StyledTooltip>
        <StyledTooltip title={'Redo'} placement="bottom">
          <StyledButton onClick={() => handleRedo()}>
            <StyledFontAwesomeIcon icon={faRotateRight} />
          </StyledButton>
        </StyledTooltip>
        <StyledTooltip title={'Rotate'} placement="bottom">
          <StyledButton onClick={() => handleRotate()}>
            <StyledFontAwesomeIcon icon={faRotate} />
          </StyledButton>
        </StyledTooltip>
      </StyledHeader>
      <PreviewContent />
      {menuVisible && (
        <RightClickMenu
          visible={menuVisible}
          x={menuPosition.x}
          y={menuPosition.y}
          transform={menuPosition.transform}
          options={previewMenuOptions}
          onClose={() => setMenuVisible(false)}
        />
      )}
    </StyledPreviewContainter>
  );
};

export default LinesheetPreviewPage;
