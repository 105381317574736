import styled from 'styled-components';
import { Row, Tooltip, Switch } from 'antd';

export const StyledTitle = styled.p`
  font-family: 'Muli', Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  margin: 0;
  padding: 16px 28px;
  background-color: #000;
`;

export const ToolsContainer = styled.div`
  padding: 16px;
`;

export const StyledSubTitle = styled.p`
  font-family: 'Muli', Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.08;
  color: #ccc;
  margin-bottom: 14px;
`;

export const StyledRow = styled(Row)`
  align-items: center;
  color: #fff !important;
  margin-bottom: ${(props) => (props.nomargin === 'true' ? '0' : '14px')};
  text-align: ${(props) => (props.textalign ? props.textalign : 'left')};
`;

export const StyledGroupTitle = styled.p`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #ccc;
  margin: 0;
`;

export const StyledTooltip = styled(Tooltip)``;

export const StyledSwitch = styled(Switch)`
  width: 20%;
  .ant-switch-handle {
    &::before {
      background-color: #adb5bd;
    }
  }
  .ant-switch-inner {
    background-color: #fff;
  }
  &.ant-switch-checked {
    .ant-switch-handle {
      &::before {
        background-color: #fff; !important;
      }
    }
    .ant-switch-inner {
      background-color: #02b183 !important;
    }
  }
`;

export const StyledChooseButton = styled.button`
  height: 34px;
  border: 2px solid #fff;
  background-color: #242424;
  font-weight: 600;
  padding: 0 8px;
  line-height: 30px;
  border-radius: 30px;
  color: #fff;
  text-align: center;
  font-size: 12px;
`;
