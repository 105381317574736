import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setInPreview } from 'slices/editorSlice';
import { faAngleLeft, faFloppyDisk, faCopy, faDownload } from '@fortawesome/free-solid-svg-icons';
import {
  StyledHeader,
  BackButton,
  StyledFontAwesomeIcon,
  HeaderTitle,
  ActionButtons,
  StyledTooltip,
  StyledButton,
} from './styles';

const LinesheetEditorHeader = ({
  linesheetName,
  setIsBack,
  handleSave,
  handleSaveAsNew,
  handleGeneratePDF,
  isBack,
  inPreview,
}) => {
  const dispatch = useDispatch();
  const [btnDisable] = useState(false);
  const handleBackClick = () => {
    if (inPreview) {
      dispatch(setInPreview(false));
    } else {
      setIsBack(!isBack);
    }
  };

  return (
    <StyledHeader>
      <BackButton onClick={handleBackClick}>
        <StyledFontAwesomeIcon icon={faAngleLeft} />
      </BackButton>
      <HeaderTitle>{`${linesheetName} - Linesheet Editor`}</HeaderTitle>
      <ActionButtons>
        <StyledTooltip title="Save" placement="bottom">
          <StyledButton onClick={handleSave}>
            <StyledFontAwesomeIcon icon={faFloppyDisk} />
          </StyledButton>
        </StyledTooltip>
        <StyledTooltip title="Save As" placement="bottom">
          <StyledButton onClick={handleSaveAsNew}>
            <StyledFontAwesomeIcon icon={faCopy} />
          </StyledButton>
        </StyledTooltip>
        <StyledTooltip
          title={btnDisable ? 'This will enable after you receive the email' : 'Save/Download'}
          placement="bottom"
        >
          <StyledButton disabled={btnDisable} onClick={handleGeneratePDF}>
            <StyledFontAwesomeIcon icon={faDownload} />
          </StyledButton>
        </StyledTooltip>
      </ActionButtons>
    </StyledHeader>
  );
};

export default LinesheetEditorHeader;
