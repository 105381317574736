import { useDispatch } from 'react-redux';
import {
  updateFontSettings,
  updateLinesheetFontSettings,
  updateGlobalProductFontSettings,
} from 'slices/editorSlice';

import { faBold, faItalic, faUnderline } from '@fortawesome/free-solid-svg-icons';

const EditorTextDecorationToolsComponent = () => {
  const dispatch = useDispatch();

  const textDecorationClickHandler = (val, isGlobal, isProductGlobal = false, type = '') => {
    const payload = { key: val, value: val, field: type };
    const action = isGlobal
      ? updateLinesheetFontSettings
      : isProductGlobal
      ? updateGlobalProductFontSettings
      : updateFontSettings;
    dispatch(action(payload));
  };

  const TextDecorationTools = [
    {
      name: 'bold',
      tooltip: 'Bold',
      icon: faBold,
      disabled: false,
      action: textDecorationClickHandler,
    },
    {
      name: 'italic',
      tooltip: 'Italic',
      icon: faItalic,
      disabled: false,
      action: textDecorationClickHandler,
    },
    {
      name: 'underline',
      tooltip: 'Underline',
      icon: faUnderline,
      disabled: false,
      action: textDecorationClickHandler,
    },
  ];

  return TextDecorationTools;
};

export default EditorTextDecorationToolsComponent;
