import styled from 'styled-components';
import AntButton from 'uiKits/Button';

export const StyledActionButtons = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
`;

export const StyledSaveButton = styled(AntButton)`
  font-family: Muli;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.96px;
  border-radius: 10000px;
  border-width: 2px;
  color: #fff;
  background-color: #02b183;
  border-color: #02b183;
  &:hover {
    color: #ffffff !important;
    background-color: #028b67 !important;
    border-color: #017f5e !important;
  }
`;

export const StyledCancelButton = styled(AntButton)`
  font-family: Muli;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.96px;
  border-radius: 10000px;
  color: #6c757d;
  background: none;
  border: none;
  box-shadow: none;
  &:hover {
    color: #ffffff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
  }
`;
