import styled from 'styled-components';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledTooltip = styled(Tooltip)`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

export const IconWrapper = styled.span`
  width: 32px;
  height: 32px;
  border: 2px solid #000000;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-bottom: 1px;
  &:hover {
    border: 2px solid #07af7c;
    svg {
      color: #07af7c;
    }
  }
`;

export const StyledImage = styled.img`
  cursor: pointer;
  &.product-toolbar-icon {
    width: 21px;
    height: 21px;
  }
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
  cursor: pointer;
`;
