import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_BASE_URL;

export const linesheetApi = createApi({
  reducerPath: 'linesheetApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    credentials: 'include',
  }),
  tagTypes: ['Linesheets'],
  endpoints: (builder) => ({
    createLinesheet: builder.mutation({
      query: (payload) => ({
        url: '/linesheets/create',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Linesheets'],
    }),
    getLinesheets: builder.query({
      query: ({ searchFilter, sortBy, currentPage, pageSize }) => {
        let url = `/linesheets?limit=${pageSize}`;
        if (searchFilter) {
          url += `&searchFilter=${encodeURIComponent(searchFilter)}`;
        }
        if (sortBy) {
          url += `&sortBy=${sortBy}`;
        }
        if (currentPage) {
          url += `&page=${currentPage}`;
        }
        return { url };
      },
      providesTags: ['Linesheets'],
    }),
    deleteLinesheets: builder.mutation({
      query: (linesheetsIds) => ({
        url: '/linesheets',
        method: 'DELETE',
        body: linesheetsIds,
      }),
      invalidatesTags: ['Linesheets'],
    }),
    getLinesheetById: builder.query({
      query: (id) => `/linesheets/${id}`,
    }),
    addLinesheetProducts: builder.mutation({
      query: ({ linesheetId, productIds, selectAll }) => ({
        url: `/linesheet_products/${linesheetId}`,
        method: 'PATCH',
        body: { productIds, selectAll },
      }),
    }),
    updateLinesheet: builder.mutation({
      query: ({ linesheetId, payload: linesheetData }) => ({
        url: `/linesheets/${linesheetId}`,
        method: 'PUT',
        body: linesheetData,
      }),
    }),
    uploadFile: builder.mutation({
      query: (file) => {
        const formData = new FormData();
        formData.append('file', file);

        return {
          url: 'file-upload',
          method: 'POST',
          body: formData,
          formData: true,
        };
      },
    }),
    getLinesheetProductsById: builder.mutation({
      query: (linesheetId) => {
        return {
          url: `/linesheet_products/${linesheetId}`,
          method: 'GET',
        };
      },
    }),
    saveAsNew: builder.mutation({
      query: (linesheetDetails) => {
        const { linesheetId, payload: linesheetData } = linesheetDetails;
        return {
          url: `/linesheets/${linesheetId}/save-as-new`,
          method: 'POST',
          body: linesheetData,
        };
      },
    }),
  }),
});

export const {
  useCreateLinesheetMutation,
  useGetLinesheetsQuery,
  useDeleteLinesheetsMutation,
  useGetLinesheetByIdQuery,
  useAddLinesheetProductsMutation,
  useUpdateLinesheetMutation,
  useUploadFileMutation,
  useGetLinesheetProductsByIdMutation,
  useSaveAsNewMutation,
} = linesheetApi;
