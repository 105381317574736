import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Select, Tooltip } from 'antd';
import AntColorPicker from 'uiKits/ColorPicker';
const { Option } = Select;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

export const StyledRow = styled(Row)`
  align-items: center;
  color: #fff !important;
  margin-bottom: ${(props) => (props.nomargin === 'true' ? '0' : '14px')};
  text-align: ${(props) => (props.textalign ? props.textalign : 'left')};
  gap: ${(props) => (props.gap ? `${props.gap}px` : '0')};
`;

export const StyledCol = styled(Col)``;

export const StyledSelect = styled(Select)`
  min-width: 100%;
  color: #fff !important;
  .ant-select-selector {
    border-radius: 3px;
    border: 2px solid #6b757c !important;
    background-color: #242424 !important;
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    color: #fff !important;
    line-height: 30px;
  }
  .ant-select-arrow,
  .ant-select-selection-item {
    color: #fff !important;
  }
`;

export const StyledOption = styled(Option)``;

export const StyledColorPicker = styled(AntColorPicker)``;

export const StyledLabel = styled.p`
  font-family: Muli, Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.08;
  text-align: ${(props) => (props.textalign ? props.textalign : 'center')};
  color: #fff;
  margin: 0 !important;
`;

export const StyledTooltip = styled(Tooltip)``;
