import React, { useState, useEffect } from 'react';
import {
  StyledManualProductVariant,
  StyledRow,
  StyledColumn,
  InputBox,
  StyledInput,
  CustomError,
} from './styles';

const ManualProductVariant = ({ variant, onChange }) => {
  const [name, setName] = useState('');
  const [productNumber, setProductNumber] = useState('');
  const [wholesalePrice, setWholesalePrice] = useState('');
  const [retailPrice, setRetailPrice] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (variant) {
      if (variant.data) {
        variant.data.name ? setName(variant.data.name) : setName('');
        variant.data.productNumber
          ? setProductNumber(variant.data.productNumber)
          : setProductNumber('');
        variant.data.wholesalePrice
          ? setWholesalePrice(variant.data.wholesalePrice)
          : setWholesalePrice('');
        variant.data.retailPrice ? setRetailPrice(variant.data.retailPrice) : setRetailPrice('');
      }
    }
  }, [variant]);

  useEffect(() => {
    let errorMessage = '';

    const wholesale = parseFloat(wholesalePrice);
    const retail = parseFloat(retailPrice);

    if (wholesale < -999999 || wholesale > 999999) {
      errorMessage = 'Whole price must be within the range of -999999 to 999999.';
    } else if (retail < -999999 || retail > 999999) {
      errorMessage = 'Retail price must be within the range of -999999 to 999999.';
    } else if (wholesale && retail && retail < wholesale) {
      errorMessage = 'Retail price must be greater than or equal to wholesale price.';
    }

    setErrorMessage(errorMessage);
  }, [wholesalePrice, retailPrice]);

  const callOnChange = (overrideKey, overrideValue) => {
    if (onChange) {
      const newData = {
        name,
        productNumber,
        wholesalePrice,
        retailPrice,
      };

      if (overrideKey) {
        newData[overrideKey] = overrideValue;
      }

      onChange(variant.id, newData);
    }
  };

  const inputChangeHandler = (event) => {
    switch (event.target.name) {
      case 'name':
        setName(event.target.value);
        callOnChange('name', event.target.value);
        break;
      case 'productNumber':
        setProductNumber(event.target.value);
        callOnChange('productNumber', event.target.value);
        break;
      case 'wholesalePrice':
        setWholesalePrice(event.target.value);
        callOnChange('wholesalePrice', event.target.value);
        break;
      case 'retailPrice':
        setRetailPrice(event.target.value);
        callOnChange('retailPrice', event.target.value);
        break;
      default:
        console.warn("Shouldn't be here");
    }
  };

  return (
    <StyledManualProductVariant>
      <StyledRow>
        <StyledColumn>
          <InputBox>
            <label htmlFor="name">Title</label>
            <StyledInput
              value={name}
              onChange={inputChangeHandler}
              type="text"
              name="name"
              placeholder="Name"
              pattern="(.|\s)*\S(.|\s)*"
              required
            />
          </InputBox>
        </StyledColumn>
        <StyledColumn>
          <InputBox>
            <label htmlFor="productNumber">
              Sku <br /> Product #
            </label>
            <StyledInput
              value={productNumber}
              onChange={inputChangeHandler}
              type="text"
              name="productNumber"
              placeholder="Product Number"
            />
          </InputBox>
          <div className="invalid-feedback text-left">Please provide a valid name</div>
        </StyledColumn>
      </StyledRow>

      <div className="form-group mb-5">
        <StyledRow>
          <StyledColumn>
            <InputBox>
              <label htmlFor="wholesalePrice">Wholesale</label>
              <StyledInput
                value={wholesalePrice}
                onChange={inputChangeHandler}
                type="number"
                name="wholesalePrice"
                placeholder="Wholesale Price"
                step="0.1"
                required
              />
            </InputBox>
          </StyledColumn>
          <StyledColumn>
            <InputBox>
              <label htmlFor="retailPrice">Retail Price</label>
              <StyledInput
                value={retailPrice}
                onChange={inputChangeHandler}
                type="number"
                name="retailPrice"
                placeholder="Retail Price"
                step="0.1"
                required
              />
            </InputBox>
          </StyledColumn>
          {errorMessage && <CustomError>{errorMessage}</CustomError>}
        </StyledRow>
      </div>
    </StyledManualProductVariant>
  );
};

export default ManualProductVariant;
