import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SetDescriptionModal from 'components/LinesheetEditor/SetDescriptionModal';
import { updateContent, setIsEditing, contentMenuActionHandler } from 'slices/editorSlice';
import { StyledContentContainer, StyledContentEditable } from './styles';

const Text = ({ data, type }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(data.settings?.isEditing || false);

  useEffect(() => {
    setOpen(data.settings?.isEditing);
  }, [data.settings?.isEditing]);

  useEffect(() => {
    if (!open && data.settings?.isEditing) dispatch(contentMenuActionHandler('editText'));
  }, [open, dispatch]);

  const closeModal = () => {
    setOpen(false);
    dispatch(setIsEditing(false));
  };

  const handleDoubleClickText = () => {
    dispatch(setIsEditing(true));
    setOpen(true);
  };

  const handleSave = (editableContent) => {
    dispatch(updateContent({ value: editableContent, type }));
    closeModal();
  };

  let textStyles = {
    fontFamily: data.settings?.font || 'Arial',
    fontSize: data.settings?.size || '10px',
    textDecoration: data.settings?.underline ? 'underline' : '',
    color: data.settings?.color || '#000',
    fontWeight: data.settings?.bold ? 'bold' : '',
    fontStyle: data.settings?.italic ? 'italic' : 'normal',
    textAlign: data.settings?.align || 'left',
  };

  return (
    <StyledContentContainer style={textStyles}>
      <StyledContentEditable
        dangerouslySetInnerHTML={{ __html: data.value }}
        onDoubleClick={handleDoubleClickText}
      />
      <SetDescriptionModal
        open={open}
        html={data.value}
        handleModalClose={closeModal}
        handleSave={handleSave}
      />
    </StyledContentContainer>
  );
};

export default Text;
