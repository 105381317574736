import React from 'react';
import { useNavigate } from 'react-router-dom';
import LinesheetThumbnail from '../LinesheetThumbnail';
import ImageWithTooltip from 'components/Common/Tooltip';
import downloadIcon from 'assets/images/download-icon.png';
import editIcon from 'assets/images/edit-icon.png';
import {
  MainLayout,
  GridContainer,
  GridItem,
  GridItemTitle,
  GridItemDetails,
  GridItemContent,
  IconWrapper,
} from './styles';

const LinesheetsGrid = ({ linesheetsData, selectedLinesheets, setIsSelectedLinesheets, showActionButtons }) => {
  const navigate = useNavigate();
  const handleEdit = (id) => {
    navigate(`/linesheet-editor/${id}`);
  };

  return (
    <MainLayout>
      <GridContainer>
        {linesheetsData.map((linesheet) => (
          <GridItem key={linesheet.id}>
            <LinesheetThumbnail
              linesheet={linesheet}
              selectedLinesheets={selectedLinesheets}
              setIsSelectedLinesheets={setIsSelectedLinesheets}
              showCheckbox={true}
            />
            <GridItemContent>
              <GridItemTitle>{linesheet?.name}</GridItemTitle>
              <GridItemDetails>
                {`${linesheet.number_of_pages} Page(s)`}
                &nbsp;&nbsp;
                {`${linesheet.number_of_products} Product(s)`}
              </GridItemDetails>
              {showActionButtons &&
                <IconWrapper>
                  <ImageWithTooltip
                    src={editIcon}
                    alt="icon"
                    title="Edit Linesheets"
                    placement="bottom"
                    onClick={() => handleEdit(linesheet.id)}
                  />
                  <ImageWithTooltip
                    src={downloadIcon}
                    alt="icon"
                    title="Download Linesheets"
                    placement="bottom"
                  />
                </IconWrapper>
              }
            </GridItemContent>
          </GridItem>
        ))}
      </GridContainer>
    </MainLayout>
  );
};

export default LinesheetsGrid;
